<template>
  <div class="addPageVehicle">
    <div class="facilityBox">
      <el-breadcrumb
        separator="/"
        :style="pageType == 'add' ? 'margin:0 0 10px 0;' : 'margin:10px 0 0 0;'"
      >
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{
          pageType == "add" ? "添加车牌信息" : "编辑车牌信息"
        }}</el-breadcrumb-item>
      </el-breadcrumb>
      <el-alert
        style="margin-top: 10px"
        type="warning"
        :closable="false"
        v-if="pageType == 'add'"
      >
        <span slot="title">
          <p>温馨提示：</p>
          <p>1.输入车牌号后添加</p>
          <p>
            2.如平台没有车辆信息，则提交；如已存在该车辆信息，可以直接引用，或提交新资料。
          </p>
          <p>3.单位：长、宽、高为米，重量为吨</p>
        </span>
      </el-alert>
      <el-form
        class="vehicleInfo"
        :model="vehicleInfo"
        ref="vehicleInfo"
        :rules="ruleVehicle"
        label-width="140px"
        inline
        @submit.native.prevent
      >
        <!-- 添加车辆 -->
        <div class="title" v-if="pageType == 'add'">添加车辆</div>
        <el-form-item label="车牌号" prop="VehicleCarNumber" v-if="pageType == 'add'">
          <el-input
            v-model="vehicleInfo.VehicleCarNumber"
            placeholder="请输入车牌号"
            style="width: 220px"
            @keyup.enter.native="addVehicle()"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="pageType == 'add'">
          <el-button
            type="primary"
            size="medium"
            @click="addVehicle()"
            icon="el-icon-zoom-in"
            >添加</el-button
          >
        </el-form-item>
        <!-- 新增 -->
        <div v-if="pageShow != ''">
          <!-- 证照信息 -->
          <div class="title">证照信息</div>
          <div class="content">
            <div class="upBox">
              <UploadImg
                ref="vehicleFace"
                :loading="vehicleImg.vehicleFace.loading"
                :uploaded="vehicleImg.vehicleFace.uploaded"
                :picUrl="vehicleImg.vehicleFace.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehicleFace(vehicleImg.vehicleFace)"
                @deleteImg="deleteImg(vehicleImg.vehicleFace)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证正页(正面)
                <i
                  @click="imgCase(14)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehiclePageOn"
                :loading="vehicleImg.vehiclePageOn.loading"
                :uploaded="vehicleImg.vehiclePageOn.uploaded"
                :picUrl="vehicleImg.vehiclePageOn.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehicleBack(vehicleImg.vehiclePageOn)"
                @deleteImg="deleteImg(vehicleImg.vehiclePageOn)"
              >
              </UploadImg>
              <div class="upText">
                <span class="required">*</span>
                车辆行驶证副页(正面)
                <i
                  @click="imgCase(15)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehicleBack"
                :loading="vehicleImg.vehicleBack.loading"
                :uploaded="vehicleImg.vehicleBack.uploaded"
                :picUrl="vehicleImg.vehicleBack.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upVehiclePageOn(vehicleImg.vehicleBack)"
                @deleteImg="deleteImg(vehicleImg.vehicleBack)"
              >
              </UploadImg>
              <div class="upText">
                车辆行驶证副页(反面)
                <i
                  @click="imgCase(16)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="vehicleRTP"
                :loading="vehicleImg.vehicleRTP.loading"
                :uploaded="vehicleImg.vehicleRTP.uploaded"
                :picUrl="vehicleImg.vehicleRTP.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upvehicleRTP(vehicleImg.vehicleRTP)"
                @deleteImg="deleteImg(vehicleImg.vehicleRTP)"
              >
              </UploadImg>
              <div class="upText">
                道路运输证
                <i
                  @click="imgCase(17)"
                  class="el-icon-question"
                  style="cursor: pointer; color: rgb(230, 160, 62)"
                >
                  案例
                </i>
              </div>
            </div>
            <div class="upBox">
              <UploadImg
                ref="elsePapers"
                :loading="vehicleImg.elsePapers.loading"
                :uploaded="vehicleImg.elsePapers.uploaded"
                :picUrl="vehicleImg.elsePapers.picUrl"
                @update:file="updateFile"
                :fileType="'Vehicle'"
                @change="upelsePapers(vehicleImg.elsePapers)"
                @deleteImg="deleteImg(vehicleImg.elsePapers)"
              >
              </UploadImg>
              <div class="upText">
                附加证照
                <!-- <i @click="imgCase(18)" class="el-icon-question" style="cursor: pointer;color:rgb(230,160,62);">
                  案例
                </i> -->
              </div>
            </div>
          </div>
          <!-- 基本信息 -->
          <div class="title">基本信息</div>
          <el-form-item label="车牌号" prop="VehicleCarNumber">
            <el-input
              v-model="vehicleInfo.VehicleCarNumber"
              placeholder="请输入车牌号"
            ></el-input>
          </el-form-item>
          <el-form-item label="车牌颜色" prop="CarNumberColor">
            <el-select
              v-model="vehicleInfo.CarNumberColor"
              clearable
              placeholder="请选择车牌颜色"
            >
              <el-option
                v-for="item in vehicleColorList"
                :key="item.Name"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="车辆类型" prop="VehicleTypeName">
            <el-select
              v-model="vehicleInfo.VehicleTypeName"
              ref="produceRef"
              clearable
              filterable
              placeholder="请选择车辆类型"
              @blur="productSelect"
              @clear="productClear"
              @change="productChange"
              allow-create
            >
              <el-option
                v-for="item in produces"
                :key="item.Id"
                :label="item.VehicleTypeName"
                :value="item.VehicleTypeName"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="所有人" prop="VehicleMaster">
            <el-input
              v-model="vehicleInfo.VehicleMaster"
              placeholder="请输入所有人"
            ></el-input>
          </el-form-item>
          <el-form-item label="载重(吨)" prop="VehicleLoad">
            <el-input
              v-model="vehicleInfo.VehicleLoad"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入载重(吨)"
            ></el-input>
          </el-form-item>
          <el-form-item label="自重(吨)" prop="VehicleTotalWeight">
            <el-input
              v-model="vehicleInfo.VehicleTotalWeight"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入自重(吨)"
            ></el-input>
          </el-form-item>
          <el-form-item label="总重(吨)" prop="VehicleLoadWeight">
            <el-input
              v-model="vehicleInfo.VehicleLoadWeight"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入总质量"
            ></el-input>
          </el-form-item>
          <el-form-item label="行驶证有效期" prop="VehicleLicenseValidDate">
            <el-date-picker
              v-model="vehicleInfo.VehicleLicenseValidDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择行驶证有效期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="车长(米)" prop="VehicleLength">
            <el-input
              v-model="vehicleInfo.VehicleLength"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入车身长度"
            ></el-input>
          </el-form-item>
          <el-form-item label="车宽(米)" prop="VehicleWidth">
            <el-input
              v-model="vehicleInfo.VehicleWidth"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入车身宽度"
            ></el-input>
          </el-form-item>
          <el-form-item label="车高(米)" prop="VehicleHeight">
            <el-input
              v-model="vehicleInfo.VehicleHeight"
              onkeyup="value=value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
              placeholder="请输入车高(米)"
            ></el-input>
          </el-form-item>
          <el-form-item label="能源类型" prop="VehiclePowerType">
            <el-select
              v-model="vehicleInfo.VehiclePowerType"
              clearable
              placeholder="能源类型"
            >
              <el-option
                v-for="item in powerList"
                :key="item.Name"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="使用性质" prop="VehicleUsage">
            <el-input
              v-model="vehicleInfo.VehicleUsage"
              placeholder="请输入使用性质"
            ></el-input>
          </el-form-item>
          <el-form-item label="品牌型号" prop="BrandModelNumber">
            <el-input
              v-model="vehicleInfo.BrandModelNumber"
              placeholder="请输入品牌型号"
            ></el-input>
          </el-form-item>
          <el-form-item label="发动机号" prop="PowerNumber">
            <el-input
              v-model="vehicleInfo.PowerNumber"
              placeholder="请输入发动机号"
            ></el-input>
          </el-form-item>
          <el-form-item label="车辆识别代码" prop="VehicleVIN">
            <el-input
              v-model="vehicleInfo.VehicleVIN"
              placeholder="请输入车辆识别代码"
            ></el-input>
          </el-form-item>
          <el-form-item label="行驶证注册日期" prop="VehicleLicenseRegDate">
            <el-date-picker
              v-model="vehicleInfo.VehicleLicenseRegDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择行驶证注册日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="行驶证发证日期" prop="VehicleLicenseAwardDate">
            <el-date-picker
              v-model="vehicleInfo.VehicleLicenseAwardDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择行驶证发证日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="道路运输证号" prop="vehicleRTP">
            <el-input
              v-model="vehicleInfo.VehicleRTP"
              placeholder="请输入道路运输证号"
            ></el-input>
          </el-form-item>
          <el-form-item label="核载人数" prop="VehicleLoadPersons">
            <el-input
              onkeyup="value=value.replace(/[^\d]/g,'')"
              v-model="vehicleInfo.VehicleLoadPersons"
              placeholder="请输入核载人数"
            ></el-input>
          </el-form-item>
          <el-form-item label="购车日期" prop="VehicleBuyDate">
            <el-date-picker
              v-model="vehicleInfo.VehicleBuyDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择购车日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="年审日期" prop="InspectAnnuallyDate">
            <el-date-picker
              v-model="vehicleInfo.InspectAnnuallyDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择年审日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="商业险到期日期" prop="BInsuranceDate">
            <el-date-picker
              v-model="vehicleInfo.BInsuranceDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择商业险到期日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="交强险到期日期" prop="CInsuranceDate">
            <el-date-picker
              v-model="vehicleInfo.CInsuranceDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择交强险到期日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="轴数" prop="VehicleAxlesNumber">
            <el-input
              v-model="vehicleInfo.VehicleAxlesNumber"
              placeholder="请输入轴数"
              maxlength="4"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="发证机关" prop="VehicleLicesenAuthority">
            <el-input
              v-model="vehicleInfo.VehicleLicesenAuthority"
              placeholder="请输入发证机关"
            ></el-input>
          </el-form-item>
          <el-form-item label="归属类型" prop="VehicleAttribution">
            <el-select
              v-model="vehicleInfo.VehicleAttribution"
              clearable
              placeholder="归属类型"
            >
              <el-option
                v-for="item in affiliation"
                :key="item.Name"
                :label="item.Name"
                :value="item.Code"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="head" v-if="pageShow != ''">
          <el-button size="medium" icon="el-icon-arrow-left" @click="routerBack()"
            >返回</el-button
          >
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-s-platform"
            @click="quoteVehicleData('vehicleInfo')"
          >
            引用平台资料</el-button
          >
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-check"
            @click="upVehicleData('vehicleInfo')"
            >提交新资料
          </el-button>

          <el-button
            type="danger"
            @click="BatchBackDraft"
            v-if="vehicleInfo.Status == 2"
            icon="el-icon-close"
          >
            退回草稿
          </el-button>
        </div>
      </el-form>
    </div>
    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="flag.isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div style="width: 100; text-align: center; font-size: 16px; color: #000">
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImg from "@/components/commonCmpt/cropperImage";
import { getDataDict, GetSampleInfo } from "@/api/common/common";
import {
  uploadVehicleFace,
  uploadVehicleBack,
  uploadVehiclePageOn,
  uploadVehicleRTP,
} from "@/api/transport/vehicleManage/verify";
import { uploadOherCertificate } from "@/api/goodsOwner/goodsOwnerManage/authInfo";
import {
  GetAuthorityByCarNumber,
  TCCarAutoCheckList,
} from "@/api/transport/vehicleManage/index";
import {
  VehicleDeatils,
  UpdateVehicleInfo,
  addVehicleInfo,
  GetTCVehicleInfo,
  SaveVehicleInfo,
} from "@/api/transport/vehicleManage/index";
import verify from "@/utils/verify";

import { BatchDraftCar } from "@/api/auditInfo/certified/index";

export default {
  data() {
    return {
      produces: [], //车辆类型
      // 页面类型
      pageType: "",
      // 当前车辆ID
      vehicleID: "",
      // 基本信息
      vehicleInfo: {
        VehicleCarNumber: "",
        CarNumberColor: "",
        VehicleType: "",
        VehicleLoad: "",
        VehicleLength: "",
        VehicleWidth: "",
        vehicleLicenseValidDate: "",
        vehicleRTP: "",
        VehicleLoadWeight: "",
        VehicleMaster: "",
        VehiclePowerType: "B",
        VehicleTotalWeight: "",
        VehicleHeight: "",
        VehicleUsage: "",
        BrandModelNumber: "",
        PowerNumber: "",
        VehicleVIN: "",
        VehicleLicenseRegDate: "",
        VehicleLicenseAwardDate: "",
        VehicleLicesenAuthority: "",
        VehicleLoadPersons: "",
        VehicleBuyDate: "",
        InspectAnnuallyDate: "",
        BInsuranceDate: "",
        CInsuranceDate: "",
        VehicleAxlesNumber: "",
        VehicleTypeName: "",
        VehicleAttribution: "",
      },
      // 证件照片
      vehicleImg: {
        //车辆行驶证(正页)
        vehicleFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(正面)
        vehicleBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //车辆行驶证副页(反面)
        vehiclePageOn: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //道路运输经营许可证
        vehicleRTP: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        //其他证件
        elsePapers: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      // 表单校验
      ruleVehicle: {
        VehicleCarNumber: [
          { required: true, message: "请输入车牌号", trigger: "change" },
        ],
        CarNumberColor: [
          { required: true, message: "请选择车牌颜色", trigger: "change" },
        ],
        VehicleLoad: [{ required: true, message: "请输入载重", trigger: "change" }],
        VehicleLength: [{ required: true, message: "请输入车长", trigger: "change" }],
        // VehicleLicenseValidDate: [{ required: true, message: '请选择行驶证有效期', trigger: 'change' }],
        VehicleTypeName: [
          { required: true, message: "请输入车辆类型", trigger: "change" },
        ],
        VehicleMaster: [{ required: true, message: "请输入所有人", trigger: "change" }],
        VehiclePowerType: [
          { required: true, message: "请输选择能源类型", trigger: "change" },
        ],
        VehicleLoadWeight: [
          { required: true, message: "请输入总重(吨)", trigger: "change" },
        ],
        VehicleLicesenAuthority: [
          { required: true, message: "请输入发证机关", trigger: "change" },
        ],
      },
      //车辆颜色
      vehicleColorList: [],
      //能源类型
      powerList: [],
      // 是否禁用
      isCompile: false,
      // 当前组件正在操作的文件
      currentFile: "",
      pageShow: "",
      // 状态集合
      flag: {
        isCase: false,
      },
      // 案例列表
      caselist: [],
      // 当前页数
      pageIndex: 0,
      // 归属类型
      affiliation: [],
    };
  },
  methods: {
    productChange(e) {
      var entity=this.produces.filter(it=>it.VehicleTypeName==e);
      if(entity.length)
      {  
        let element=entity[0];
        this.vehicleInfo.VehiclePowerType=element.PowerCode  //能源类型
        this.vehicleInfo.CarNumberColor=element.CarNumberColorCode    //车牌颜色
        this. vehicleInfo.VehicleLoadWeight=element.TotalWeightMax   //总重(吨)
        this. vehicleInfo.VehicleLoad=element.TotalLoadWeightMax   //载重(吨)
        this. vehicleInfo.VehicleLength=element.VehicleLenghtMax  //车长(米
      }

    },
    productClear()
    {
        this.vehicleInfo.VehiclePowerType=""  //能源类型
        this.vehicleInfo.CarNumberColor=""    //车牌颜色
        this. vehicleInfo.VehicleLoadWeight=""    //总重(吨)
        this. vehicleInfo.VehicleLoad=""   //载重(吨)
        this. vehicleInfo.VehicleLength=""   //车长(米
    },
    productSelect(e) {
      let value = e.target.value; // 输入框值
      if (value) {
        // 只有输入才有这个值，下拉框选择的话 这个值为空
        this.vehicleInfo.VehicleTypeName = value;
      } 
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/transport/vehicleManage/index",
        query: {
          pageIndex: this.pageIndex,
          VehicleCarNumber: this.$route.query.VehicleCarNumber,
          VehicleLoad: this.$route.query.VehicleLoad,
          VehicleLicenseValidDate: this.$route.query.VehicleLicenseValidDate || ["", ""],
          Status: this.$route.query.Status,
          pagesize: this.$route.query.pagesize || 10,
        },
      });
    },
    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.flag.isCase = true;
      });
    },
    // 通过车牌搜索
    addVehicle() {
      if (this.vehicleInfo.VehicleCarNumber == "") {
        this.$message({
          message: "请输入车牌进行查询",
          type: "warning",
        });
        return;
      }
      GetTCVehicleInfo({ CarNumber: this.vehicleInfo.VehicleCarNumber }).then((res) => {
        //没查询到清空原有的信息  测试提的需求
        if (res.baseInfo == null) {
          for (let key in this.baseInfo) {
            this.vehicleInfo[key] = "";
          }
          for (let key in this.vehicleImg) {
            this.vehicleImg[key] = {
              loading: false,
              uploaded: false,
              picUrl: "",
              file: "",
            };
          }
        }
        if (res.baseInfo) {
          this.vehicleInfo = res.baseInfo;
          this.vehicleImg.vehicleFace.picUrl = res.confirm.VehicleLicenseFrontPageURL;
          this.vehicleImg.vehicleBack.picUrl = res.confirm.VehicleLicenseSubPageBackURL;
          this.vehicleImg.vehiclePageOn.picUrl = res.confirm.VehicleLicenseSubPageOnURL;
          this.vehicleImg.vehicleRTP.picUrl = res.confirm.VehicleRTPUTL;
          this.vehicleImg.elsePapers.picUrl = res.confirm.AdditionalLicenseURL;
        }
        this.pageType = "add";
        this.pageShow = "add";
      });
    },
    // 引用平台资料
    quoteVehicleData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseinfo: [this.vehicleInfo],
            confirmInfo: [
              {
                vehicleID: "",
                vehicleLicenseFrontPageURL: this.vehicleImg.vehicleFace.picUrl,
                vehicleLicenseSubPageOnURL: this.vehicleImg.vehiclePageOn.picUrl,
                vehicleLicenseSubPageBackURL: this.vehicleImg.vehicleBack.picUrl,
                vehicleRTPUTL: this.vehicleImg.vehicleRTP.picUrl,
                AdditionalLicenseURL: this.vehicleImg.elsePapers.picUrl,
              },
            ],
            type: 0,
          };
          if (params.confirmInfo[0].vehicleLicenseFrontPageURL == "") {
            this.$message.error("请上传行驶证正页（正面）");
            return;
          }
          if (params.confirmInfo[0].vehicleLicenseSubPageOnURL == "") {
            this.$message.error("请上传行驶证副页（正面）");
            return;
          }
          if (verify._isCarNum(params.baseinfo[0].VehicleCarNumber) == false) {
            this.$message({
              message: "车牌输入有误，区分大小写，请重新输入",
              type: "warning",
            });
            return;
          }
          if (params.baseinfo[0].VehicleLoad == "") {
            this.$message({
              message: "请重新输入载重",
              type: "warning",
            });
            return;
          }
          if (params.baseinfo[0].VehicleLength == "") {
            this.$message({
              message: "请重新输入车长",
              type: "warning",
            });
            return;
          }
          this.$confirm(
            "您选择直接引用平台已存的资料！后续您所有涉及该车辆的业务都将使用这份资料作为合规依据",
            "温馨提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            addVehicleInfo(params).then((res) => {
              this.routerBack();
            });
          });
        }
      });
    },
    // 上传车辆信息
    upVehicleData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            baseinfo: this.vehicleInfo,
            confirmInfo: {
              vehicleID: this.vehicleID ? this.vehicleID : "",
              vehicleLicenseFrontPageURL: this.vehicleImg.vehicleFace.picUrl,
              vehicleLicenseSubPageOnURL: this.vehicleImg.vehiclePageOn.picUrl,
              vehicleLicenseSubPageBackURL: this.vehicleImg.vehicleBack.picUrl,
              vehicleRTPUTL: this.vehicleImg.vehicleRTP.picUrl,
              AdditionalLicenseURL: this.vehicleImg.elsePapers.picUrl,
            },
            type: 0,
          };
          params.baseinfo.Status = 0;
          if (params.confirmInfo.vehicleLicenseFrontPageURL == "") {
            this.$message.error("请上传行驶证正页（正面）");
            return;
          }
          if (params.confirmInfo.vehicleLicenseSubPageOnURL == "") {
            this.$message.error("请上传行驶证副页（正面）");
            return;
          }
          this.$confirm(
            "您选择提交人员新资料，如被驳回，则平台保留现有资料作为依据，视为您已知悉和认同！",
            "温馨提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            if (this.vehicleID) {
              UpdateVehicleInfo(params).then((res) => {
                this.routerBack();
              });
            } else {
              SaveVehicleInfo(params).then((res) => {
                this.routerBack();
              });
            }
          });
        }
      });
    },
    // 上传附件
    upelsePapers(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadOherCertificate(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.ConsInfo;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.elsePapers.dialog();
        });
    },
    // 上传车辆道路运输证照片
    upvehicleRTP(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleRTP(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          let obj = res.data ? res.data : {};
          field.picUrl = obj.ImgUrl;
          this.vehicleInfo.VehicleRTP = obj.Detail ? obj.Detail.SerialNumber : "";
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleRTP.dialog();
        });
    },
    // 上传行驶证副页（反面）
    upVehiclePageOn(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehiclePageOn(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleBack.dialog();
        });
    },
    // 上传行驶证副页（正面）
    upVehicleBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles.vehicleBackUrl;
          try {
            this.vehicleInfo.VehicleHeight = res.vehicles.overall_dimension
              ? Number(
                  res.vehicles.overall_dimension
                    .split("X")[2]
                    .slice(0, res.vehicles.overall_dimension.split("X")[2].length - 2)
                ) / 1000
              : "";
            this.vehicleInfo.VehicleLength =
              this.vehicleInfo.VehicleTypeName == "重型半挂牵引车"
                ? 13.5
                : res.vehicles.overall_dimension
                ? Number(res.vehicles.overall_dimension.split("X")[0]) / 1000
                : "";
            this.vehicleInfo.VehicleWidth = res.vehicles.overall_dimension
              ? Number(res.vehicles.overall_dimension.split("X")[1]) / 1000
              : "";
          } catch (error) {
            console.log(error);
          }
          this.vehicleInfo.VehicleLoad = res.vehicles.approved_load
            ? Number(res.vehicles.approved_load.split("kg")[0]) / 1000
            : "";
          this.vehicleInfo.VehicleLoadWeight = res.vehicles.gross_mass
            ? Number(res.vehicles.gross_mass.split("kg")[0]) / 1000
            : "";
          this.vehicleInfo.VehicleTotalWeight = res.vehicles.unladen_mass
            ? Number(res.vehicles.unladen_mass.split("kg")[0]) / 1000
            : "";
          //总质量25000KG为3轴，31000kg为4轴，牵引40000kg为3轴，25000kg以下均为2轴
          if (
            this.vehicleInfo.VehicleLoadWeight == 40 ||
            this.vehicleInfo.VehicleLoadWeight == 25
          ) {
            this.vehicleInfo.VehicleAxlesNumber = 3;
          } else if (this.vehicleInfo.VehicleLoadWeight == 31) {
            this.vehicleInfo.VehicleAxlesNumber = 4;
          } else if (this.vehicleInfo.VehicleLoadWeight < 25) {
            this.vehicleInfo.VehicleAxlesNumber = 2;
          }
          if (res.vehicles.appproved_passenger_capacity) {
            let str = res.vehicles.appproved_passenger_capacity;
            if (verify._isDecimals(str) == false) {
              this.vehicleInfo.VehicleLoadPersons = str;
            } else {
              this.vehicleInfo.VehicleLoadPersons = "";
            }
          } else {
            this.vehicleInfo.VehicleLoadPersons = "";
          }
          if (res.vehicles.energyType) {
            this.powerList.forEach((item) => {
              if (item.Name == res.vehicles.energyType) {
                this.vehicleInfo.VehiclePowerType = item.Code;
              }
            });
          }
          if (res.vehicles.vehicleColor) {
            this.vehicleColorList.forEach((item) => {
              if (item.Name == res.vehicles.vehicleColor) {
                this.vehicleInfo.CarNumberColor = item.Code;
              }
            });
          }
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehiclePageOn.dialog();
        });
    },
    // 上传行驶证正面
    upVehicleFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadVehicleFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.vehicles.vehicleFaceUrl;
          this.vehicleInfo.VehicleCarNumber =
            this.vehicleInfo.VehicleCarNumber && this.pageType != "add"
              ? this.vehicleInfo.VehicleCarNumber
              : res.vehicles.plate_num || "";
          this.vehicleInfo.VehicleMaster = res.vehicles.owner ? res.vehicles.owner : "";
          this.vehicleInfo.VehicleUsage = res.vehicles.use_character
            ? res.vehicles.use_character
            : "";
          this.vehicleInfo.VehicleTypeName = res.vehicles.vehicle_type
            ? res.vehicles.vehicle_type
            : "";
          this.vehicleInfo.VehicleVIN = res.vehicles.vin ? res.vehicles.vin : "";
          this.vehicleInfo.PowerNumber = res.vehicles.engine_num
            ? res.vehicles.engine_num
            : "";
          this.vehicleInfo.BrandModelNumber = res.vehicles.model
            ? res.vehicles.model
            : "";
          this.vehicleInfo.VehicleLicenseRegDate = res.vehicles.register_date
            ? this.insertStr(res.vehicles.register_date, 4, "-", 7, "-")
            : "";
          this.vehicleInfo.VehicleLicenseAwardDate = res.vehicles.issue_date
            ? this.insertStr(res.vehicles.issue_date, 4, "-", 7, "-")
            : "";
          this.vehicleInfo.VehicleLicesenAuthority = res.vehicles.Authority
            ? res.vehicles.Authority
            : "";
          if (this.vehicleInfo.VehicleTypeName == "重型半挂牵引车") {
            this.vehicleInfo.VehicleLength = 13.5;
          }
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.vehicleFace.dialog();
        });
    },
    //识别日期插入
    insertStr(soure, firstPosition, firstStr, secondPosition, secondStr) {
      soure = soure.slice(0, firstPosition) + firstStr + soure.slice(firstPosition);
      return soure.slice(0, secondPosition) + secondStr + soure.slice(secondPosition);
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },
    //自动审核失败批量处理
    BatchBackDraft() {
      let data = {
        Remark: "",
        Details: [],
      };

      let params = {
        CarNumber: this.vehicleInfo.VehicleCarNumber,
        AscriptionUserID: this.vehicleInfo.AscriptionUserID,
      };
      data.Details.push(params);

      this.$confirm("确定退回该车辆的审核信息吗", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        BatchDraftCar(data).then((res) => {
          this.$message.success("操作成功!");
          this.routerBack();
        });
      });
    },
    setTCCarAutoCheckList() {
      TCCarAutoCheckList().then((res) => {
        this.produces = res.data;
        console.log(this.produces);
      });
    },
  },
  created() {
    this.setTCCarAutoCheckList();
    // 获取页面类型
    this.pageType = this.$route.query.type;
    this.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1;
    // 添加类型
    if (this.$route.query.type == "add") {
      this.vehicleID = "";
      this.vehicleInfo.vehicleID = "";
    } else {
      // 编辑类型
      this.vehicleID = this.$route.query.id;
      this.vehicleInfo.vehicleID = this.$route.query.id;
      VehicleDeatils({ id: this.$route.query.id }).then((res) => {
        this.vehicleInfo = res.vehicles[0];
        this.vehicleImg.vehicleFace.picUrl = res.vehicles[0].VehicleLicenseFrontPageURL;
        this.vehicleImg.vehicleBack.picUrl = res.vehicles[0].VehicleLicenseSubPageBackURL;
        this.vehicleImg.vehiclePageOn.picUrl = res.vehicles[0].VehicleLicenseSubPageOnURL;
        this.vehicleImg.vehicleRTP.picUrl = res.vehicles[0].VehicleRTPUTL;
        this.vehicleImg.elsePapers.picUrl = res.vehicles[0].AdditionalLicenseURL;
      });
      this.pageShow = this.$route.query.type;
    }
    // 车牌颜色
    getDataDict({ type: 3 }).then((res) => {
      this.vehicleColorList = res.patterSetInfo;
    });
    // 能源类型
    getDataDict({ type: 10 }).then((res) => {
      this.powerList = res.patterSetInfo;
    });
    // 车辆归属类型
    getDataDict({ type: 15 }).then((res) => {
      this.affiliation = res.patterSetInfo;
    });
    setTimeout(() => {
      if (
        this.vehicleInfo.VehicleCarNumber &&
        !this.vehicleInfo.VehicleLicesenAuthority
      ) {
        GetAuthorityByCarNumber({
          carnumber: this.vehicleInfo.VehicleCarNumber,
        }).then((res) => {
          this.vehicleInfo.VehicleLicesenAuthority = res.data;
        });
      }
    }, 500);
  },
  components: {
    UploadImg,
  },
};
</script>

<style lang="scss">
.addPageVehicle {
  .el-input__inner {
    width: 220px;
  }

  .el-form-item__content {
    width: 220px;
  }

  .el-input.is-disabled .el-input__inner {
    color: #000;
  }
}
</style>

<style lang="scss" scoped>
@import "../../../../assets/style/variable.scss";

.addPageVehicle {
  .head {
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: $textGray2;
    }
  }

  .title {
    color: $primary;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    border-bottom: 1px solid $borderGray;
    padding: 20px 0;
    margin-bottom: 20px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: $primary;
    }
  }
}
</style>
